import React, { Component } from 'react';
import ContactForm1 from '../../widgets/contact/contactform1';
import Pageheading from '../../widgets/Pageheading';
import Header from '../../layout/header/header';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Particles from "react-tsparticles";

class Contact_thanks extends Component {
    constructor(props) {
        super(props)
        this.state = {
            particlesOptions: {
                "particles": {
                    "number": {
                        "value": 760,
                        "density": {
                            "enable": true,
                            "value_area": 800
                        }
                    },  
                    "color": {
                        "value":["#BD10E0", "#B8E986", "#50E3C2", "#FFD300", "#E86363"]
                    },
                    "shape": {
                        "type": "circle",
                        "stroke": {
                            "width": 0,
                            "color": "#b6b2b2"
                            
                        },
                        "polygon": {
                            "nb_sides": 5
                        },
                        "image": {
                            //"src": "img/github.svg",
                            "width": 100,
                            "height": 100
                        }
                    },
                    "opacity": {
                        "value": 1,
                        "random": true,
                        "anim": {
                            "enable": true,
                            "speed": 1,
                            "opacity_min": 0,
                            "sync": false
                        }
                    },
                    "size": {
                        "value": 3,
                        "random": true,
                        "anim": {
                            "enable": false,
                            "speed": 4,
                            "size_min": 0.3,
                            "sync": false
                        }
                    },
                    "line_linked": {
                        "enable": false,
                        "distance": 150,
                        "color": "#ffffff",
                        "opacity": 0.4,
                        "width": 1
                    },
                    "move": {
                        "enable": true,
                        "speed": 1,
                        "direction": "none",
                        "random": true,
                        "straight": false,
                        "out_mode": "out",
                        "bounce": false,
                        "attract": {
                            "enable": false,
                            "rotateX": 600,
                            "rotateY": 600
                        }
                    }
                },
                "interactivity": {
                    "detect_on": "window",
                    "events": {
                        "onhover": {
                            "enable": true,
                            "mode": "bubble"
                        },
                        "onclick": {
                            "enable": true,
                            "mode": "repulse"
                        },
                        "resize": true
                    },
                    "modes": {
                        "grab": {
                            "distance": 400,
                            "line_linked": {
                                "opacity": 1
                            }
                        },
                        "bubble": {
                            "distance": 250,
                            "size": 0,
                            "duration": 2,
                            "opacity": 0,
                            "speed": 3
                        },
                        "repulse": {
                            "distance": 400,
                            "duration": 0.4
                        },
                        "push": {
                            "particles_nb": 4
                        },
                        "remove": {
                            "particles_nb": 2
                        }
                    }
                },
                "retina_detect": true
            }
        }
    }
  render() {
    const { t } = this.props;
    return (
      <div>
        {/*header start*/}
        <Header />
        {/*hero section start*/}
        <section className="position-relative">
        <div className="container">
            <Particles id="particles-js" params={this.state.particlesOptions} />
        </div>
        </section>
        {/*hero section end*/}
        {/*body content start*/}
        <div style={{ padding: "200px 10px", margin:"0 20%" }}>
                    <div style={{ paddingBottom:"20px", backgroundColor: "#fffff", color: "black", letterSpacing: "0.5px", fontSize: "20px", fontWeight: 700, lineHeight: "22px" }}>
                        {t('ContactThanksTxt')}
                    </div>
                    <Link to="/" style={{
                            textAlign: "center", color: "rgb(14, 48, 71)", borderBlockColor: "#007bff", borderBottomStyle: "solid", borderBottomWidth: "1px", fontSize: "16px",
                            fontWeight: 500, zIndex:"1"
                           
                        }}>{t('backToHomeBtn')}
                    </Link>
                </div>
        {/*body content end*/}
      </div>
    );
  }
}

export default withTranslation()(Contact_thanks);