import React, { Component } from 'react';
import Contacthelper from './Contacthelper';
import { withTranslation } from 'react-i18next';

class ContactForm1 extends Contacthelper {
    render() {   
        const { t } = this.props; 
        return (
            <div className="row justify-content-center text-center">
                <div className="col-12 col-lg-10">
                    <form onSubmit={this.handleSubmit} method="GET" id="contact-form" className="row">
                        <div className="messages" />
                        <div className="form-group col-md-6">
                            <input id="form_name" value={this.state.fisrt_name} onChange={this.onFirstNameChange} type="text" name="first_name" className="form-control" placeholder={t('firstNameTxt')} required="required" data-error="Fisrt Name is required." />
                            <div className="help-block with-errors" />
                        </div>
                        <div className="form-group col-md-6">
                            <input id="form_name1" value={this.state.last_name} onChange={this.onLastNameChange} type="text" name="last_name" className="form-control" placeholder={t('lastNameTxt')} required="required" data-error="Last Name is required." />
                            <div className="help-block with-errors" />
                        </div>
                        <div className="form-group col-md-12">
                            <input id="form_email" value={this.state.email} onChange={this.onEmailChange} type="email" name="email" className="form-control" placeholder="Email" required="required" data-error="Valid email is required." />
                            <div className="help-block with-errors" />
                        </div>
                        <div className="form-group col-md-12">
                            <input id="form_phone"value={this.state.phone} onChange={this.onPhoneChange} type="tel" name="phone" className="form-control" placeholder={t('phoneTxt')} required="required" data-error="Phone is required" />
                            <div className="help-block with-errors" />
                        </div>
                      
                     
                        <div className="form-group col-md-12">
                            <textarea id="form_message" value={this.state.message} onChange={this.onMessageChange} name="message" className="form-control" placeholder={t('messageTxt')} rows={4} required="required" data-error="Please,leave us a message." defaultValue={""} />
                            <div className="help-block with-errors" />
                        </div>
                        <div className="col-md-12 text-center mt-4"> 
                            <button  type="submit" className="btn btn-outline-primary"><span>{t('sendMessageTxt')}</span>
                            </button>
                        </div>
                    </form>
                </div>
            </div>

        );
    }
}

export default withTranslation()(ContactForm1);