/**
 *  Main Menu Json
 */
export default [
   {
      "menu_title": "homeTxt",
      "type": "Menu",
      "path": "/",
      "icon": "home",
   },
   {
      "menu_title": "princingTxt",
      "type": "Menu",
      "path": "/pricing",
      "icon": "home",
   },
   {
      "menu_title": "contactTxt",
      "type": "Menu",
      "path": "/contact-us",
      "icon": "home",
   },
   {
      "menu_title": "AboutUsTxt",
      "type": "Menu",
      "path": "/about-us",
      "icon": "home",
   },
]
