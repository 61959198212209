import React, { Component } from 'react';
import PricingBoard from '../../../widgets/utilities/pricingboard';
import AccordionDemo from '../../../widgets/featuare/accordiondemo';
import Pageheading from '../../../widgets/Pageheading';
import Header from '../../../layout/header/header';
import { withTranslation } from 'react-i18next';

class Pricing extends Component {
  constructor(props) {
    super(props)  
  }
  componentDidMount() {
    window.scrollTo(0, 0)
  }
    render() {
      const { t } = this.props;
        return (
            <div>
              {/*header start*/}
            <Header />
            {/*hero section start*/}
            <section className="position-relative">
            <Pageheading foldername={"Utilities"} title={t('princingTxt')} />
            </section>
            {/*hero section end*/}  
            {/*body content start*/}
            <div className="page-content">
              {/*pricing start*/}
              <PricingBoard />
               {/*pricing end*/}
              
            </div>
            {/*body content end*/}
          </div>
        );
    }
}

export default withTranslation()(Pricing);