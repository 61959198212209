import { t } from 'i18next';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';

class Footer extends Component {
    render() {
        const { t } = this.props;
        return (
            <footer className="pt-6 pb-2  position-relative" style={{ backgroundImage: `url(${`src/assets/images/gradient_color_new.jpg`}`, backgroundPosition: 'center center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }} data-overlay={1}>
                <div className="shape-1" style={{ height: '150px', overflow: 'hidden' }}>
                    <svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{ height: '100%', width: '100%' }}>
                        <path d="M0.00,49.98 C150.00,150.00 271.49,-50.00 500.00,49.98 L500.00,0.00 L0.00,0.00 Z" style={{ stroke: 'none', fill: '#fff' }} />
                    </svg>
                </div>
                <Container className=" mt-4">

                        <Row className="col-sm-12" >
                            <Col style={{ paddingTop: "10%" }} className="col-sm-6">
                                <Link className="footer-logo text-dark h2 mb-0  pt-12" to="/">
                                    <img src={'src/assets/images/logo.png'} style={{ width: "250px" }} />
                                </Link>   
                            </Col>
                            <Col className="col-sm-3 mt-sm-0 mt-4 navbar-dark">
                                <h5 className="mb-4 text-dark">Pages</h5>
                                <ul className="navbar-nav list-unstyled mb-0">
                                    <li className="mb-3 nav-item"><Link className="text-dark" to="/">{t('homeTxt')}</Link>
                                    </li>
                                    <li className="mb-3 nav-item"><Link className="text-dark" to="/pricing">{t('princingTxt')}</Link>
                                    </li>
                                    <li className="mb-3 nav-item"><Link className="text-dark" to="/contact-us">{t('contactTxt')}</Link>
                                    </li>
                                    <li className="mb-3 nav-item"><Link className="text-dark" to="/about-us">{t('AboutUsTxt')}</Link>
                                    </li>
                                </ul>
                            </Col>

                            <Col className="col-sm-3 mt-sm-0 mt-4 navbar-dark">
                                <h5 className="mb-4 text-dark">Legal</h5>
                                <ul className="navbar-nav list-unstyled mb-0">
                                    <li className="mb-3 nav-item"><Link className="text-dark" to="/term-condition">Term Of Service</Link>
                                    </li>
                                    <li className="mb-3 nav-item"><Link className="text-dark" to="/privacy-policy">Privacy Policy</Link>
                                    </li>
                                    <li className="nav-item"><Link className="text-dark" to="#">Support</Link>
                                    </li>
                                </ul>
                            </Col>

                            <Col className="col-sm-6 mt-6 mt-sm-0">
                                <ul className="list-inline mb-0">
                                    <li className="list-inline-item"><Link className="text-light ic-2x" to="#"><i className="la la-facebook" /></Link>
                                    </li>
                                    <li className="list-inline-item">
                                        <Link className="text-light ic-2x" to={{ pathname: "https://www.instagram.com/tsohar_agency/" }} target="_blank" >
                                            <i className="la la-instagram" /></Link>
                                    </li>
                                    <li className="list-inline-item">
                                        <Link className="text-light ic-2x" to={{ pathname: "https://www.linkedin.com/company/tsohar-agency/" }} target="_blank" >
                                            <i className="la la-linkedin" /></Link>
                                    </li>

                                </ul>
                            </Col>
                        </Row>
                    <Row className="mb-4 mb-sm-0  text-dark text-center ">
                        <Col className="col-12  mt-3 mt-sm-0">
                            <hr className="mb-4" /> © Copyright 2022 Tsohar Group. Develop by{" "}

                            <i className="mdi mdi-heart text-danger"></i>{" "}
                            <a
                                href="https://tsohar.com/"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-reset"
                            >
                                Tsohar Agency
                            </a>
                            .
                        </Col>
                    </Row>
                </Container>
            </footer>
        );
    }
}

export default withTranslation()(Footer);