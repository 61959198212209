import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

class FeatureServices extends Component {
    render() {  
        const { t } = this.props;     
        return (
            <div className="row align-items-center">
                <div className="col-xl-4 col-lg-4 mb-8 mb-lg-0" >
                <div className="card  shadow border-4 hover-translate" style={{borderColor:"#007bff", borderWidth:"3px"}}>
                <div style={{height:"460px"}} className={`px-4 py-7 rounded  text-center bg-white shadow`}>
                        <div>
                        <img className="img-fluid" src={(`src/assets/images/svg/quality.png`)} alt="" style={{maxWidth:"50%"}} />
                        </div>
                        <h5 className="mt-4 mb-3">{t('homeService1Title')}</h5>
                        <p>{t('homeService1Desc')}</p>
                    </div>
                    </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-sm-6">
                <div className="card  shadow border-4 hover-translate" style={{borderColor:"#007bff", borderWidth:"3px"}}>

                <div style={{height:"460px"}} className={`px-4 py-7 rounded  text-center bg-white shadow`}>
                        <div>
                        <img className="img-fluid" src={(`src/assets/images/svg/efficacity.png`)} alt="" style={{maxWidth:"50%", backgroundColor:"white"}} />
                        </div>
                        <h5 className="mt-4 mb-3">{t('homeService2Title')}</h5>
                        <p>{t('homeService2Desc')}</p>
                    </div>
                    </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-sm-6 mt-6 mt-sm-0">
                <div className="card  shadow border-4 hover-translate" style={{borderColor:"#007bff", borderWidth:"3px"}}>
                <div style={{height:"460px"}} className={`px-4 py-7 rounded  text-center bg-white shadow`}>
                            <div>
                            <img className="img-fluid" src={(`src/assets/images/svg/security.png`)} alt="" style={{maxWidth:"50%"}} />
                        </div>
                        <h5 className="mt-4 mb-3">{t('homeService3Title')}</h5>
                        <p>  {t('homeService3Desc')}</p>
                    </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default withTranslation()(FeatureServices);