import React, { Component } from "react";
import { Col, Container, Row } from "reactstrap";

import BackgroundSlider from "react-background-slider";
import ReactTextRotator from 'react-text-rotator';

// Import images
import bg01 from "../../assets/images/bg/bg1.jpg";
import bg02 from "../..//assets/images/bg/bg5.jpg";
import bg03 from "../..//assets/images/bg/bg4.jpg";
import { Link } from "react-router-dom";
import { withTranslation } from 'react-i18next';




class Section extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [bg01, bg02, bg03],
    };
  }
  render() {
    const { t } = this.props;
    const rotatetext = [
      {
        text: t('homeTitle'),
        animation: "false",
      },
      {
        text: t('homeTitle'),
        animation: "false",
      },
      {
        text: t('homeTitle'),
        animation: "false",
      },
    ];
    return (
      <div className="home-slider position-relative">
        <div className="carousel slide">
          <div className="carousel-inner">
            <div className="carousel-item  active">
              <div
                className="bg-half-170"
              >
                <BackgroundSlider
                  images={this.state.items}
                  duration={8}
                  transition={1}
                />
                <div className="bg-overlay"></div>
                <Container>
                  <Row className="mt-5 justify-content-center">
                    <Col xs={12}>
                      <div className="title-heading text-center">
                        <h1 className="display-4 text-dark font-weight-bold">
                          <ReactTextRotator
                            content={rotatetext}
                            time={8000}
                          />
                        </h1>                          
                         {/* <ul className="list-unstyled">
                          <li className="list-inline-item small user text-white-50 me-2">
                            <i className="mdi mdi-account text-white title-dark"></i>{" "}
                            Calvin Carlo
                          </li>
                          <li className="list-inline-item small date text-white-50">
                            <i className="mdi mdi-calendar-check text-white title-dark"></i>{" "}
                            13th August, 2019
                          </li>
                        </ul>
                        <p className="para-desc mx-auto text-white-50 mb-0">
                          Start working with Landrick that can provide everything
                          you need to generate awareness, drive traffic, connect.
                        </p> */}
                        <Link to="/contact-us" className="btn btn-light">
                   {t('getStartedTxt')}
                      </Link>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withTranslation()(Section);
  