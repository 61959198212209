import React, { Component } from 'react';
import Memberaboutus from '../../widgets/aboutus/aboutus';
import FeatureServices from '../../widgets/featurebox/services';
import Testimonial1 from '../../widgets/testimonial/testimonial1';
import Blog4 from '../../widgets/blog/blog4';
import Pageheading from '../../widgets/Pageheading';
import Worktab from '../../widgets/featuare/worktab';
import Header from '../../layout/header/header';
import { withTranslation } from 'react-i18next';



class Aboutus extends Component {
    constructor(props) {
        super(props)
    }
    componentDidMount() {
        window.scrollTo(0, 0)
    }
    render() {
        const { t } = this.props;
        return (
            <div>
                {/*header start*/}
                <Header />
                <section className="position-relative">
                    <Pageheading foldername={"Company"} title={t('AboutUsTxt')} />
                </section>
                <div className="page-content">
                    {/*about start*/}
                    <section>
                        <div className="container">
                            <Memberaboutus />
                        </div>
                    </section>
                    {/*about end*/}


                    {/*work start*/}
                    <section>
                        <div className="container">
                            <Worktab />
                        </div>
                    </section>
                    {/*work end*/}
                </div>
            </div>
        );
    }
}

export default withTranslation()(Aboutus);