import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

class Featureskill extends Component {
    render() {
        const { t } = this.props;

        return (
    
                <div className="row align-items-center">
                    <div className="col-lg-4 col-12 text-lg-right">
                        <div className="d-flex align-items-start mb-8">
                            <div className="order-lg-1 ml-lg-3">
                                <div  style={{backgroundColor:"#CE3A3A"}} className="f-icon-shape-sm text-white  rounded-circle shadow-primary mr-3 mr-lg-0"> <i className="la la-wechat ic-2x" />
                                </div>
                            </div>  
                            <div>
                                <h5>{t('featureBoxHomeTitle1')}</h5>
                                <p className="mb-0">{t('featureBoxHomeDesc1')}</p>
                            </div>
                        </div>
                        <div className="d-flex align-items-start mb-8">
                            <div className="order-lg-1 ml-lg-3">
                                <div  style={{backgroundColor:"#CE3A3A"}} className="f-icon-shape-sm text-white  rounded-circle shadow-primary mr-3 mr-lg-0"> <i className="la la-desktop ic-2x" />
                                </div>
                            </div>
                            <div>
                                <h5>{t('featureBoxHomeTitle2')}</h5>
                                <p className="mb-0">{t('featureBoxHomeDesc2')}</p>
                            </div>
                        </div>
                        <div className="d-flex align-items-start">
                            <div className="order-lg-1 ml-lg-3">
                                <div  style={{backgroundColor:"#CE3A3A"}}  className="f-icon-shape-sm text-white  rounded-circle shadow-primary mr-3 mr-lg-0"> <i className="ti-check-box ic-2x" />
                                </div>
                            </div>
                            <div>
                                <h5>{t('featureBoxHomeTitle3')}</h5>
                                <p className="mb-0">{t('featureBoxHomeDesc3')}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-12 my-5 my-lg-0">
                        <img src={(`src/assets/images/about/seo_iphone.png`)} alt="Image" className="img-fluid" />
                    </div>
                    <div className="col-lg-4 col-12">
                        <div className="d-flex align-items-start mb-8">
                            <div>
                                <div style={{backgroundColor:"#CE3A3A"}} className="f-icon-shape-sm text-white  rounded-circle shadow-primary mr-3"> <i className="la la-eye ic-2x" />
                                </div>
                            </div>
                            <div>  
                                <h5 >{t('featureBoxHomeTitle4')}</h5>
                                <p className="mb-0">{t('featureBoxHomeDesc4')}</p>
                            </div>
                        </div>
                        <div className="d-flex align-items-start mb-8">
                            <div>
                                <div style={{backgroundColor:"#CE3A3A"}}  className="f-icon-shape-sm text-white  rounded-circle shadow-primary mr-3"> <i className="la la-film ic-2x" />
                                </div>
                            </div>
                            <div>
                                <h5>{t('featureBoxHomeTitle5')}</h5>
                                <p className="mb-0">{t('featureBoxHomeDesc5')}</p>
                            </div>
                        </div>
                        <div className="d-flex align-items-start">
                            <div>
                                <div style={{backgroundColor:"#CE3A3A"}}  className="f-icon-shape-sm text-white  rounded-circle shadow-primary mr-3"> <i className="la la-headphones ic-2x" />
                                </div>
                            </div>
                            <div>
                                <h5>{t('featureBoxHomeTitle6')}</h5>
                                <p className="mb-0">{t('featureBoxHomeDesc6')}</p>
                            </div>
                        </div>
                    </div>
                </div>
         
      
        );
    }
}

export default withTranslation()(Featureskill);