import React, { Component, useRef, useEffect } from 'react';
import ContactForm1 from '../../widgets/checkout/checkoutform';
import Pageheading from '../../widgets/Pageheading';
import Header from '../../layout/header/header';
import { useParams } from "react-router";

import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";


function mapPlanToPrice(month, plan) {
  const map_arr = {
    "basicTxt": { 6: "590", 12: "550" },
    "standard": { 6: "1099", 12: "950" },
    "extendedTxt": { 6: "1890", 12: "1790" },
  };

  if (month && plan && map_arr.hasOwnProperty(plan)  ) {
    return map_arr[plan][month];
  }
  else return null;
}

function mapPlanToPlanId(month, plan) {
  const map_arr = {
    "basicTxt": { 6: "P-01498417RC7978416MJFKZ2Y", 12: "P-51P10046HM704804WMJFK5CA" },
    "standard": { 6: "P-92J311511S175035NMJEYOIQ", 12: "P-9UN0864007602131UMJFK4YA" },
    "extendedTxt": { 6: "P-8UR304998J426882HMJFK5QY", 12: "P-09H43052399746919MJFK5YQ" },
  };

  if (month && plan && map_arr.hasOwnProperty(plan)  ) {
    return map_arr[plan][month];
  }
  else return null;
}

function Checkout(props) {
  const { t } = useTranslation();
  const params = new URLSearchParams(window.location.search);
  const month_val = params.get('month');
  const plan_val = params.get('plan');
  const plan_price = mapPlanToPrice(month_val, plan_val);
  const plan_str = t("planStrTxt", { plan_val:t(plan_val), plan_price: plan_price, month_val: month_val });

  const paypal = useRef();
  <script
    src="https://www.paypal.com/sdk/js?client-id=AR5vWyKXCklrew13Rzpaw1KMeW-6JUN4Q6df3AT-Gyt3nGYN3sdaf7OvrFMJ7NcfHb0h_eyYLJgrdfIk&vault=true&intent=subscription"
    data-sdk-integration-source="button-factory"
  ></script>;

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  useEffect(() => {
    if (true) {

      window.paypal
        ?.Buttons({
          createSubscription: (data, actions) => {
            return actions.subscription.create({
              plan_id: mapPlanToPlanId(month_val, plan_val),
            });
          },
          onApprove: async (data, actions) => {
            alert('You have successfully created subscription ' + data.subscriptionID);


            console.log(data);
          },
          onError: (err) => {
            // Here need to display message to tell error happen
            console.log('An Error occurs in the subscription payment ' + err);
          },
        })
        .render(paypal.current);
    }
  }, []);


  return (
    <div>
      {/*header start*/}
      <Header />
      {/*hero section start*/}
      <section className="position-relative">
        <Pageheading foldername={"Company"} title={t('checkoutTitle')} />
      </section>
      {/*hero section end*/}
      {/*body content start*/}
      <div className="page-content">
        <section>
          <div className="container">

            <div className="p-3 p-lg-5 border">
              <h3 className="mb-3">{t('yourPlanTxt')}</h3>

              <ul className="list-unstyled">
                <li className="mb-3 border-bottom pb-3"><span> {plan_str.toUpperCase()} </span></li>
                <li className="mb-3 border-bottom pb-3"><span> {t('billingMonthlyTxt')} </span> </li>
                  {/* <li className="mb-3 border-bottom pb-3"><span> Subtotal </span> $ {temp_item.ProductName}</li> */}
                <li><span><strong className="cart-total"> Total :</strong> {plan_price}</span>  <strong className="cart-total">€{ } </strong>
                </li>
              </ul>


            </div>

              <div className="p-3 p-lg-5 border">
                <ContactForm1 />
            </div>

            <a style={{ zIndex: 1, position: 'relative', textAlign: 'center' }} ref={paypal}></a>

          </div>

        </section>
      </div>



      {/*body content end*/}
    </div>
  );
}

export default Checkout;