import React, { Component } from 'react';
import Herosection3 from '../../widgets/herosection/herosection3';
import Header from '../../layout/header/header';
import FeatureServices from '../../widgets/featurebox/services';
import Team1 from '../../widgets/team/team1';
import Pricingplan1 from '../../widgets/pricingplan/pricingplan1';
import Blog2 from '../../widgets/blog/blog2';
import Counter4 from '../../widgets/counter/counter4';
import { withTranslation } from 'react-i18next';
import Featureskill from '../../widgets/featurebox/featureskill';
import Section from './Section';

class Home extends Component {
  constructor(props)
  {
      super(props)
  }
  componentDidMount() {
      window.scrollTo(0, 0)
  }
    render() {
      const { t } = this.props;

        return (
            <div>
            {/*header start*/}
            <Header/>
             {/*header end*/}
            {/*hero section start*/}
            <Section />
            {/* <Herosection3 /> */}
            {/*hero section end*/} 
            {/*body content start*/}
            <div className="page-content">
              {/*services start*/}
              <section>
                <div className="container">
                  <div className="row justify-content-center text-center">
                    <div className="col-12 col-md-12 col-lg-8 mb-8 mb-lg-0">
                      <div className="mb-8"> <span className="badge badge-primary-soft p-2 font-w-6">
                        </span>
                        <h2 className="mt-3 font-w-5">{t('serviceHomeTitle')}</h2>
                        {/* <p className="lead mb-0">{t('serviceHomeDesc')}</p> */}
                      </div>
                    </div>
                  </div>
                  {/* / .row */}
                  <FeatureServices />
                 </div>
              </section>
              {/*services end*/}
              {/* about start
              <section>
                <div className="container">
                  <div className="row align-items-center justify-content-between">
                    <div className="col-12 col-lg-6 mb-8 mb-lg-0">

                      <img src={`src/assets/images/gradient_color.jpg`} alt="Image" className="img-fluid" />
                    </div>
                    <div className="col-12 col-lg-6 col-xl-5">
                      <div> <span className="badge badge-primary-soft p-2 font-w-6">
                          About Bootsland
                        </span>
                        <h2 className="mt-3 font-w-5">{t('aboutHomeTitle')}</h2>
                        <p className="lead mb-0">{t('aboutHomeDesc')}</p>
                      </div>  
                    </div>
                  </div>
                </div>
              </section> */}
              {/*about end*/}
              {/*how it work start*/}
              <section style={{ backgroundImage: `url(${`src/assets/images/gradient_blue.jpg`}`}} className="custom-py-2 position-relative bg-white" data-bg-img={(`src/assets/images/gradient_color_new.jpg`)}>
                <div className="container">
                  <div className="row justify-content-center text-center">
                    <div className="col-12 col-md-12 col-lg-8 mb-8 mb-lg-0">
                      <div className="mb-8"> <span  style={{color:"#007bff"}}className="badge badge-primary-soft p-2 font-w-6">
                        {t("ourVisionTxt")}</span>
                        <h2 style={{color:"#007bff"}} className="mt-3 font-w-5 mb-0">{t('howItsWorkHomeBigTitle')}</h2>
                      </div>
                    </div>
                  </div>
                  {/* / .row */}
                  <div className="row align-items-center justify-content-between mb-10">
                    <div className="col-12 col-lg-6 mb-6 mb-lg-0">
                      <img src={(`src/assets/images/technology.png`)} alt="Image" className="img-fluid" />
                    </div>
                    <div className="col-12 col-lg-6 col-xl-5">
                      <div>
                      
                        <h4 style={{color:"#007bff"}}  className="mt-3">{t('howItsWorkHomeTitle1')}</h4>
                        <p  className="lead mb-0 text-dark">{t('howItsWorkHomeDesc1')}</p>
                      </div>
                    </div>  
                  </div>
                  <div  className="row align-items-center justify-content-between mb-10">
                    <div className="col-12 col-lg-6 order-lg-1 mb-6 mb-lg-0">
                    
                      <img style={{color:"white"}} src={(`src/assets/images/ourServices_2.png`)} alt="Image" className="img-fluid" />
                    </div>
                    <div className="col-12 col-lg-6 col-xl-5">
                      <div>
                   
                        <h4 style={{color:"#007bff"}} className="mt-3">{t('howItsWorkHomeTitle2')}</h4>
                        <p className="lead mb-0 text-dark">{t('howItsWorkHomeDesc2')}</p>
                      </div>
                    </div>
                  </div>
                  <div className="row align-items-center justify-content-between">
                    <div className="col-12 col-lg-6 mb-6 mb-lg-0">
                      <img src={(`src/assets/images/results.png`)} alt="Image" className="img-fluid" />
                    </div>
                    <div className="col-12 col-lg-6 col-xl-5">
                      <div>
                      
                        <h4 style={{color:"#007bff"}} className="mt-3 ">{t('howItsWorkHomeTitle3')}</h4>
                        <p className="lead mb-0 text-dark">{t('howItsWorkHomeDesc3')}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="shape-1" style={{height: '200px', overflow: 'hidden'}}>
                  <svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{height: '100%', width: '100%'}}>
                    <path d="M0.00,49.98 C150.00,150.00 271.49,-50.00 500.00,49.98 L500.00,0.00 L0.00,0.00 Z" style={{stroke: 'none', fill: '#fff'}} />
                  </svg>
                </div>
                <div className="shape-1 bottom" style={{height: '200px', overflow: 'hidden'}}>
                  <svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{height: '100%', width: '100%'}}>
                    <path d="M0.00,49.98 C150.00,150.00 349.20,-50.00 500.00,49.98 L500.00,150.00 L0.00,150.00 Z" style={{stroke: 'none', fill: '#fff'}} />
                  </svg>
                </div>
              </section>
              {/*how it work end*/}
               {/*services start*/}
          <section className="p-0">
            <div className="container">
              <div className="row justify-content-center text-center">
                <div className="col-12 col-md-12 col-lg-8 mb-8">
                  <div> <span className="badge badge-primary-soft p-2">
                    <i className="la la-cubes ic-3x rotation" />
                  </span>
                    <h2 className="mt-3">{t('homeFeaturesTitle')}</h2>
                    {/* <p className="lead mb-0">{t('homeFeaturesDesc')}</p> */}
                  </div>
                </div>
              </div>
              <Featureskill />
            </div>
          </section>
             
            </div>
            {/*body content end*/}
          </div>
        );
    }
}

export default withTranslation()(Home);