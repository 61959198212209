import React, { Component, Fragment } from 'react';
const axios = require('axios');

//app.use(bodyParser.json())

class Checkouthelper extends Component {
    constructor(props) {
        super(props);
        this.state = {
            first_name: '',
            last_name: '',
            phone: '',
            email: '',
            message: '',
            isVerified: false
        }
        this.onFirstNameChange = this.onFirstNameChange.bind(this);
        this.onLastNameChange = this.onLastNameChange.bind(this);
        this.onPhoneChange = this.onPhoneChange.bind(this);
        this.onEmailChange = this.onEmailChange.bind(this);
        this.onMessageChange = this.onMessageChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    onFirstNameChange(event) {
        this.setState({ first_name: event.target.value })
    }
    onLastNameChange(event) {
        this.setState({ last_name: event.target.value })
    }
    onPhoneChange(event) {
        this.setState({ phone: event.target.value })
    }
    onEmailChange(event) {
        this.setState({ email: event.target.value })
    }
    onMessageChange(event) {
        this.setState({ message: event.target.value })
    }
   

    async handleSubmit(e) {
        const json = this.state;
        e.preventDefault();
        try {
            const res =  axios.post("http://localhost:8085/mails/contact", json);
            console.log(this.state);
            window.location.href = '/contact-thanks';

        }
        catch (err) {
            // Handle Error Here
            console.error(err);
        }
    }

    resetForm() {
        this.setState({ first_name: "",last_name:"", phone: "", email: "", message: "", })
    }
    render() {
        return (
            <Fragment />
        );
    }
}

export default Checkouthelper;
