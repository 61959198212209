import React, { Component, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import i18n from 'i18next';
import {
    Container,
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink,
    Row,
    Col,
} from "reactstrap";
import classnames from "classnames";

class PricingBoard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: "1",
        };
        this.toggle = this.toggle.bind(this);
    }

    toggle(tab) {
        this.setState({ activeTab: tab });
    }

    render() {
        const { t } = this.props;
        const features_keys = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];
        const features = [
            { title: `6 ${t('monthsTxt')}` },
            { title:  `12 ${t('monthsTxt')}` }

        ]


        return (
            <section>
                <div className="container">
                    <Row className="justify-content-center mb-5">
                        <Col lg={8} md={12} className="text-center">
                            <Nav
                                pills
                                justified
                                id="pills-tab"
                                className="flex-column flex-sm-row rounded"
                            >
                                {features.map((feature, index) =>
                                    <NavItem>
                                        <NavLink
                                            className={classnames(
                                                { active: this.state.activeTab === (index + 1).toString() },
                                                "rounded", "border"
                                            )}
                                            onClick={() => {
                                                this.toggle((index + 1).toString());
                                            }}
                                        >
                                            <div className="text-center py-2">
                                                <h6 className="mb-0">
                                                    {feature.title}
                                                </h6>
                                            </div>
                                        </NavLink>
                                    </NavItem>)}
                            </Nav>
                        </Col>
                    </Row>

                    {/* / .row */}
                    <div className="row align-items-begin">
                        <div className="col-12 col-lg-4 mb-8 mb-lg-0">
                            {/* Card */}
                            <div className="card  shadow border-4 hover-translate" style={{borderColor:"#007bff", borderWidth:"2px"}}>
                                {/* Body */}
                                <div className="card-body py-11 px-6">
                                    {/* Badge */}
                                    <div className="text-center my-5"> <span className="badge shadow badge-primary-soft">
                                        <span className="h6 text-uppercase">{t('basicTxt')}</span>
                                    </span>  
                                    </div> 
                                    {/* Price */}  
                                    <div className="d-flex justify-content-center"> <span className="h2 mb-0 mt-2">€</span>
                                        <span className="price display-2">{this.state.activeTab == 1 ? 590 : 550}</span>
                                        <span  style={{color:"#007bff"}} className="h2 align-self-end">/mo</span>
                                    </div>
                                    {/* Text */}
                                    <p className="text-center text-muted mb-6 mb-md-8">{t('allIncludedTxt')}</p>
                                    {/* Features */}
                                    {features_keys.slice(0, 6).map((key) =>
                                        <div className="d-flex align-items-start justify-content-between">
                                            {/* Text */}
                                            <p>{i18n.t('price1_features.' + key)}</p>
                                            {/* Check */}
                                            <div className="ml-4"> <i className="la la-check text-primary font-weight-bold" />
                                            </div>
                                        </div>
                                    )}

                                    {/* Button */} <Link to={{
                                        pathname: "/checkout",
                                        search: `?month=${this.state.activeTab == 1 ? 6 : 12}&plan=basicTxt`
                                    }}
                                         className="btn btn-block btn-outline-primary mt-5">
                                         {t('choosePackageTxt')}
                                     </Link>
                                </div>
                            </div>  
                        </div>
                       
                        <div className="col-12 col-lg-4 mb-8 mb-lg-0">
                            {/* Card */}
                            <div className="card  shadow border-4 hover-translate" style={{borderColor:"#007bff", borderWidth:"2px"}}>
                                {/* Body */}
                                <div className="card-body py-11 px-6">
                                     {/* Text */}
                                    <p  style={{fontWeight:"600"}} className="text-center text-muted ">{t('recommendedTxt')}</p>
                                    {/* Badge */}
                                    <div className="text-center my-5"> <span className="badge shadow badge-danger-soft">
                                        <span className="h6 text-uppercase">Standard</span>
                                    </span>  
                                    </div> 
                                    {/* Price */}  
                                    <div className="d-flex justify-content-center"> <span className="h2 mb-0 mt-2">€</span>
                                        <span style={{color:"#ce3a3a"}} className="price display-2">{this.state.activeTab == 1 ? 1099 : 950}</span>
                                        <span  style={{color:"#ce3a3a"}} className="h2 align-self-end">/mo</span>
                                    </div>
                                    {/* Text */}
                                    <p className="text-center text-muted mb-6 mb-md-8">{t('allIncludedTxt')}</p>
                                    {/* Features */}
                                    {features_keys.slice(0, 11).map((key,index) =>
                                        <div className="d-flex align-items-start justify-content-between">
                                            {/* Text */}
                                            <p style={{fontWeight:`${index >=6 ? '600' : '400'}`}}>{i18n.t('price2_features.' + key)}</p>
                                            {/* Check */}
                                            <div className="ml-4"> <i className="la la-check text-primary font-weight-bold" />
                                            </div>
                                        </div>
                                    )}

                                    {/* Button */} <Link to={{
                                        pathname: "/checkout",
                                        search: `?month=${this.state.activeTab == 1 ? 6 : 12}&plan=standard`
                                    }}
                                         className="btn btn-block btn-outline-primary mt-5">
                                         {t('choosePackageTxt')}
                                     </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-4 mb-8 mb-lg-0">
                            {/* Card */}
                            <div className="card  shadow border-4 hover-translate" style={{borderColor:"#007bff", borderWidth:"2px"}}>
                                {/* Body */}
                                <div className="card-body py-11 px-6">
                                    {/* Badge */}
                                    <div className="text-center my-5"> <span className="badge shadow badge-primary-soft">
                                        <span className="h6 text-uppercase">{t('extendedTxt')}</span>
                                    </span>
                                    </div>
                                    {/* Price */}
                                    <div className="d-flex justify-content-center"> <span className="h2 mb-0 mt-2">€</span>
                                        <span  className="price display-2">{this.state.activeTab == 1 ? 1890 : 1790}</span>
                                        <span style={{color:"#007bff"}} className="h2 align-self-end">/mo</span>
                                    </div>
                                     {/* Text */}
                                     <p className="text-center text-muted mb-6 mb-md-8">{t('allIncludedTxt')}</p>
                                    {/* Features */}

                                    {features_keys.slice(0, 12).map((key,index) =>
                                        <div className="d-flex align-items-start justify-content-between">
                                            {/* Text */}
                                            <p style={{fontWeight:`${index >=6 ? '600' : '400'}`}}>{i18n.t('price3_features.' + key)}</p>
                                            {/* Check */}
                                            <div className="ml-4"> <i className="la la-check text-primary font-weight-bold" />
                                            </div>
                                        </div>
                                    )}


                                   {/* Button */} <Link to={{
                                        pathname: "/checkout",
                                        search: `?month=${this.state.activeTab == 1 ? 6 : 12}&plan=extendedTxt`
                                    }}
                                         className="btn btn-block btn-outline-primary mt-5">
                                         {t('choosePackageTxt')}
                                     </Link>
                                </div>
                            </div>
                        </div>
                  
                    </div>
                    {/* / .row */}
                </div>
                {/* / .container */}
            </section>
        );
    }
}

export default withTranslation()(PricingBoard);