import React, { Component, useState, useEffect } from 'react';
import navLinks from '../../api/NavLinks';
import { Link } from 'react-router-dom';
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    NavbarText
} from 'reactstrap'
import * as flagIcons from '../../assets/icons/flags';
import i18n from 'i18next';
import { useTranslation } from "react-i18next";

const FlagIcon = ({ name }) => {
    const TagName = flagIcons[name];
    return !!TagName ? <TagName /> : <p>Invalid icon {name}</p>;
};

function Header(props) { 
    const [isOpen, set_isOpen] = useState(false);
    const [visible, set_visible] = useState(false);
    const [loader, set_loader] = useState(false);
    const [headername, set_headername] = useState(window.location.pathname);

    const { t } = useTranslation();

    const toggle = () => {
        set_isOpen(prevState => !prevState)
        //console.log("toggle called")
    }
    const handleClick = (event) => {
        var elems = document.querySelectorAll(".childsubmenu");
        [].forEach.call(elems, function (el) {
            el.classList.remove("show");
        });
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        }
    }, [])

    function handleScroll() {
        var scrollTop = (document.documentElement && document.documentElement.scrollTop) ||
            document.body.scrollTop;
        if (scrollTop > 100) {
            set_visible(true)
        }
        else {
            set_visible(false)
        }

    }
    //console.log('this.context.router.route.location.pathname', this.context);
    //console.log('this.props.to', this.props.to);
    if (loader == true) {
        setTimeout(function () {
            set_loader(false)
        }, 2000);
    }
    return (  
        <header  className={`${(headername == "/index2") ? "site-header  bg-dark navbar-dark" : "site-header"}`}>
            {(loader == false) ?
                <div id="header-wrap" className={` ${"position-absolute w-100 z-index-1"} ${(visible) ? "fixed-header " : ""}`}>
                    <div className="container">
                        <div className="row">
                            {/*menu start*/}
                            <div className="col d-flex align-items-center justify-content-between">
                                <Link className="navbar-brand logo text-dark h2 mb-0" to="/">
                                    <img src="src/assets/images/logo.png" style={{ width: "190px" }} />
                                    {/* Boots<span className="text-primary font-weight-bold">Land.</span> */}
                                </Link>    

                                <Navbar className="navbar-expand-lg navbar-light ml-auto">
                                    <NavbarToggler onClick={() => toggle()} />
                                    <Collapse isOpen={isOpen} className=" navbar-collapse" navbar>
                                        <Nav className="ml-auto" navbar>
                                            {navLinks.map((navLink, index) => (
                                                (navLink.type && navLink.type === 'subMenu') ?
                                                    <UncontrolledDropdown nav inNavbar key={index}>
                                                        <DropdownToggle nav caret >
                                                            {navLink.menu_title}
                                                        </DropdownToggle>
                                                        <DropdownMenu id={`submenu_${index}`} className="childsubmenu">
                                                            {navLink.child_routes && navLink.child_routes.map((subNavLink, index) => (
                                                                (subNavLink.type && subNavLink.type === 'childsubMenu') ?
                                                                    <UncontrolledDropdown nav inNavbar className="dropdown-submenu" key={index}>
                                                                        <DropdownToggle nav caret className="dropdown-item" >
                                                                            {subNavLink.menu_title}
                                                                        </DropdownToggle>
                                                                        <DropdownMenu id={`childsubmenu_${index}`}>
                                                                            {subNavLink.child_routes && subNavLink.child_routes.map((ChildsubNavLink, i) =>
                                                                                <DropdownItem key={i} tag={Link} to={ChildsubNavLink.path} onClick={() => handleClick} >{ChildsubNavLink.menu_title}
                                                                                </DropdownItem>
                                                                            )}
                                                                        </DropdownMenu>
                                                                    </UncontrolledDropdown>
                                                                    :
                                                                    <DropdownItem key={index} tag={Link} to={subNavLink.path}>{subNavLink.menu_title}
                                                                    </DropdownItem>
                                                            ))}
                                                        </DropdownMenu>
                                                    </UncontrolledDropdown>
                                                    :
                                                    <NavItem>
                                                        <NavLink href={navLink.path}> {t(navLink.menu_title)}</NavLink>
                                                    </NavItem>
                                            ))}
                                        </Nav>
                                    </Collapse>

                                </Navbar>

                                {/* LANGUAGE MENU */}
                                <ul className="buy-button list-inline mb-0 px-3">
                                    <li className="list-inline-item mb-0">
                                        <UncontrolledDropdown>
                                            <DropdownToggle tag="a" className="btn btn-link text-decoration-none p-0 pe-2">
                                                <span>
                                                    <FlagIcon name={i18n.language === "en" ? "USFlag" : "FRFlag"} />
                                                </span>
                                            </DropdownToggle>
                                            <DropdownMenu className="dd-menu dropdown-menu-end bg-white shadow rounded border-0 mt-3 py-0" style={{ width: '240px' }}>
                                                <DropdownItem style={{
                                                    width: "10px",
                                                    lineHeight: "1.2em",
                                                    display: " block",
                                                    padding: "15px 30px",
                                                    alignItems: "center",
                                                    border: "0",
                                                    backgroundColor: "transparent",
                                                    outline: "0",
                                                    cursor: "pointer"
                                                }} onClick={() => {
                                                    i18n.changeLanguage("en");
                                                    localStorage.setItem('language', 'en');
                                                }}>
                                                    <span style={{ color: "black" }}>
                                                        <FlagIcon name={"USFlag"} />
                                                        {' EN'}
                                                    </span>
                                                </DropdownItem>
                                                <DropdownItem style={{
                                                    width: "10px",
                                                    lineHeight: "1.2em",
                                                    display: " block",
                                                    padding: "15px 30px",
                                                    alignItems: "center",
                                                    border: "0",
                                                    backgroundColor: "transparent",
                                                    outline: "0",
                                                    cursor: "pointer"
                                                }}
                                                    onClick={() => {
                                                        i18n.changeLanguage("fr");
                                                        localStorage.setItem('language', 'fr');
                                                    }}>
                                                    <span style={{ color: "black" }}>
                                                        <FlagIcon name={"FRFlag"} />
                                                        {' FR'}
                                                    </span>
                                                </DropdownItem>
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    </li>
                                </ul>
                                {/* END LANGUAGE MENU */}
                            </div>

                            {/*menu end*/}
                        </div>
                    </div>
                </div>
                :
                <div id="ht-preloader">
                    <div className="loader clear-loader">
                        <span />
                        <p>BootsLand</p>
                    </div>
                </div>
            }
        </header>
    );

}

export default Header;