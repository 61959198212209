import React, { Component, Fragment } from 'react';
import { HashRouter, Switch, Route, withRouter } from 'react-router-dom';
import Header from './layout/header/header';
import Footer from './layout/footer/footer';
import i18n from 'i18next';
import { connect } from 'react-redux';

import $ from 'jquery';

import './App.css';
import './Vendor.js';

import Pricing from './pages/utilities/pricing';
import Aboutus from './pages/aboutus';
import Contact2 from './pages/contact';
import Checkout from './pages/checkout';
import Contact_thanks from './pages/contact_thanks';
import Home from './pages/home/index';

import PageNotFound from './pages/utilities/404';

  
import Scrolltop from './layout/back-to-top';

class App extends React.Component {
  constructor(props) {
    super(props)
  }

  getUrl(pathname) {
    let pathArray = pathname.split('/');
    return `/${pathArray[1]}` === '/coming-soon' ? true : `/${pathArray[1]}` === '/maintenance' ? true : `/${pathArray[1]}` === '/page-not-found' ? true : false;
  }
  setHeader(pathname) {
    let pathArray = pathname.split('/');
    return `/${pathArray[1]}` === '/' ? true : `/${pathArray[1]}` === '/contact-us' ? true : `/${pathArray[1]}` === '/about-us' ? true : `/${pathArray[1]}` === '/pricing' ? true : false;
  }
  Loader = () => {
    (localStorage.getItem('language') === 'en') ? i18n.changeLanguage("en") : i18n.changeLanguage("fr");
    return (
      <div id="preloader">
        <div id="status">
          <div className="spinner">
            <div className="double-bounce1"></div>
            <div className="double-bounce2"></div>
          </div>
        </div>
      </div>
    );
  };
  render() {
    const { location } = this.props;
    return (
      <Fragment>
        {
          this.getUrl(location.pathname) ?
            <Switch>
              <Route path="/page-not-found" component={PageNotFound} />
            </Switch>
            :
            <div className="page-wrapper">
              {this.setHeader(location.pathname) ? null : <Header />}
              <Switch>

                <Route exact path="/" component={Home} />

                {/* Company Page */}
                <Route path="/about-us" component={Aboutus} />

                {/* Contact Page */}
                <Route path="/contact-us" component={Contact2} />
                <Route exact path="/contact-thanks" component={Contact_thanks} />

                {/* Checkout Page */}
                <Route path="/checkout" component={Checkout} />

                {/* Utilitie Page */}
                <Route path="/pricing" component={Pricing} />

              </Switch>
              <Footer />
              <Scrolltop />
            </div>
        }
      </Fragment>
    );
  }
}

const AppMapStateToProps = state => {
  return {
    products: state.data.products
  };
};

const AppMapDispatchToProps = dispatch => {
  return {
    getProducts: () => {
      dispatch(getProducts());
    }
  };
};


export default connect(AppMapStateToProps, AppMapDispatchToProps)(withRouter(App))

